import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-62d21b5e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tk-lottery-Real"
};
import lottery from '@/components/page/lottery.vue';

import { useRouter } from 'vue-router';
import { ref } from 'vue';
export default {
  __name: 'lotteryReal',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    lotterys: {
      type: String,
      default: '2'
    }
  },
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const router = useRouter();
    let checked = ref(false);
    let colse = () => {
      emits('update:show', false);
    };
    let overlayPop = () => {
      emits('update:show', false);
    };
    let addUser = () => {
      router.push({
        path: '/me/setup/BindingAccount'
      });
    };
    return (_ctx, _cache) => {
      const _component_van_overlay = _resolveComponent("van-overlay");
      return _openBlock(), _createBlock(_component_van_overlay, {
        show: __props.show,
        "z-index": "1000"
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "wrapper",
          onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => _unref(overlayPop) && _unref(overlayPop)(...args), ["stop"]))
        }, [_createElementVNode("div", _hoisted_1, [_createVNode(lottery, {
          lottery: __props.lotterys
        }, null, 8, ["lottery"])])])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};